import { create } from 'zustand';
import { Persona, personas } from '../constants/personas';

interface PersonaState {
  activePersona: Persona;
  setActivePersona: (persona: Persona) => void;
}

// Find the genz persona for default
const defaultPersona = personas.find((p) => p.name === 'lusty') || personas[0];

export const usePersonaStore = create<PersonaState>((set) => ({
  activePersona: defaultPersona,
  setActivePersona: (persona) => { set({ activePersona: persona })},
})); 