import { useEffect, useState } from 'react';
import Spline from '@splinetool/react-spline';
import Navbar from '../components/Navbar';
import { useMediaQuery } from 'usehooks-ts';
import { useOpenAiRealtime } from '../hooks/use-openai-realtime';
import { useGeminiLive } from '../hooks/use-gemini-live';
import './ConsolePage.scss';
import { useModel, ModelCatalogue } from '../contexts/ModelContext';
import Tray from '../components/Tray';
import Transcript from '../components/Transcript';
import { motion, AnimatePresence } from 'framer-motion';
import { CaretLeft, VideoCamera, PhoneCall } from '@phosphor-icons/react';
import ModelOptions from '../components/Tray/ModelOptions';
import ModelCard from '../components/Tray/ModelCard';

export function ConsolePage() {
  const {
    model,
    specificModelKey,
  } = useModel();
  const isPhone = useMediaQuery('(max-width: 480px)');
  const [splineKey, setSplineKey] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    setSplineKey((prev) => prev + 1);
  }, [model]);

  // OpenAI specific state and handlers
  const {
    isConnected: isOpenAiConnected,
    connect: connectOpenAI,
    disconnect: disconnectOpenAI,
    items,
    onSplineLoad: onSplineLoadOpenAI,
    client: openAiClient,
  } = useOpenAiRealtime();

  // Gemini specific state and handlers
  const {
    isConnected: isGeminiConnected,
    connect: connectGemini,
    disconnect: disconnectGemini,
    onSplineLoad: onSplineLoadGemini,
    client: geminiClient,
  } = useGeminiLive();

  const client = model === 'openai' ? openAiClient : geminiClient;
  const isConnected =
    model === 'openai' ? isOpenAiConnected : isGeminiConnected;

  const handleConnect = async () => {
    if (model === 'openai') {
      await connectOpenAI();
    } else {
      await connectGemini();
    }
  };

  const handleDisconnect = async () => {
    if (model === 'openai') {
      await disconnectOpenAI();
    } else {
      await disconnectGemini();
    }
  };

  // Auto-scroll conversation logs for OpenAI
  useEffect(() => {
    if (!items?.length) return;

    const conversationEls = document.querySelectorAll(
      '[data-conversation-content]'
    );
    conversationEls.forEach((el) => {
      (el as HTMLDivElement).scrollTop = (el as HTMLDivElement).scrollHeight;
    });
  }, [items]);

  return (
    <div data-component="ConsolePage">
      <Navbar />

      <div className="flex">
        {/* Sidebar Toggle Button */}
        {!isPhone && (
          <motion.button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            animate={{ x: isSidebarOpen ? 400 : 0 }}
            transition={{ type: "spring", stiffness: 200, damping: 25 }}
          className="fixed left-0 top-24 z-30 bg-black/50 px-1 py-3 rounded-r-md border border-white/20 hover:border-white/70 transition-colors"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <CaretLeft
            size={20}
              style={{ transform: `rotate(${isSidebarOpen ? 0 : 180}deg)`, transition: 'transform 0.3s ease' }}
            />
          </motion.button>
        )}

        {/* Animated Sidebar */}
        <AnimatePresence>
          {isSidebarOpen && (
            <motion.div
              initial={{ x: -400 }}
              animate={{ x: 0 }}
              exit={{ x: -400 }}
              transition={{ type: "spring", stiffness: 200, damping: 25 }}
              className="flex flex-col w-[400px] h-screen bg-black/50 border-r border-white/20 fixed left-0 top-0 z-20"
            >
              <div className="p-4 mt-20 space-y-4">
                {ModelCatalogue.map((model) => (
                  <ModelCard key={model.key} model={model} />
                ))}
              </div>
              {/* {(ModelCatalogue.find(model => model.key === specificModelKey)?.key.startsWith('muse')) && (
                <div className="flex justify-center items-center h-full">
                  <ModelOptions isConnected={isConnected} />
                </div>
              )} */}
            </motion.div>
          )}
        </AnimatePresence>

        {/* Main Content */}
        <motion.div
          className="content-main md:p-[40px] flex flex-col w-full"
          animate={{
            marginLeft: isSidebarOpen ? "400px" : "0px"
          }}
          transition={{ type: "spring", stiffness: 200, damping: 25 }}
        >
          <div className={`fixed top-0 ${isSidebarOpen ? 'left-[400px]' : 'left-0'} right-0 h-screen transition-all duration-300`}>
            <Spline
              key={splineKey}
              scene="https://prod.spline.design/rZW2hRuaa6hLD8Qy/scene.splinecode"
              className="relative !w-full !h-screen"
              onLoad={
                model === 'openai' ? onSplineLoadOpenAI : onSplineLoadGemini
              }
            />
          </div>
          {model === 'openai' && <Transcript items={items} />}
          <div className={`z-10 mt-auto pointer-events-auto content-actions absolute bottom-0 ${isSidebarOpen ? 'left-[400px]' : 'left-0'} right-0 transition-all duration-300`}>
            <Tray
              isConnected={isConnected}
              handleConnect={handleConnect}
              handleDisconnect={handleDisconnect}
              client={client}
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
}
