import { createContext, useContext, useState, ReactNode } from 'react';
import { defaultGeminiVoice, defaultOpenaiVoice } from '../constants/voices';

type ModelType = 'openai' | 'gemini';
type SpecificModelType = 'muse-echo' | 'muse-sage' | 'Ω SN21 Latest' | 'ronan401/magic_baby';

const SN21_LATEST = 'Ω SN21 Latest';

type ModelCatalogueEntry = {
  key: SpecificModelType;
  name: string;
  description: string;
  hotkey: string | null;
  block: number | null;
  hasVideo: boolean;
}

export const ModelCatalogue: ModelCatalogueEntry[] = [
  {
    key: 'muse-echo',
    name: 'Smartest Voice Model',
    description: 'Natural and expressive voice',
    hotkey: null,
    block: null,
    hasVideo: false,
  },
  {
    key: 'muse-sage',
    name: 'Best Multimodal Understanding',
    description: 'Smoothest video and voice integration',
    hotkey: null,
    block: null,
    hasVideo: true,
  },
  {
    key: SN21_LATEST,
    name: SN21_LATEST,
    description: 'Smartest opensource Ω SN21 model',
    hotkey: '5ERwSN3qgPAsXmeZjkkJBXs7sFkaqyynGSPhNXtyA5sCTFX1',
    block: 4825665,
    hasVideo: true,
  },
  // {
  //   key: 'ronan401/magic_baby',
  //   name: 'ronan401/magic_baby',
  //   description: 'First ever SN21 model, clear voice but not very smart',
  //   hotkey: '5DXi2PF9Dhj7fiMEMUNZsw1bFZfpUDS1L5Z9bHvTmG8kYSVG',
  //   block: 4618177,
  //   hasVideo: false,
  // }
];

interface ModelContextType {
  model: ModelType;
  setModel: (model: ModelType) => void;
  geminiVoice: string;
  setGeminiVoice: (voice: string) => void;
  openAiVoice: string;
  setOpenAiVoice: (voice: string) => void;
  specificModelKey: SpecificModelType;
  setSpecificModelKey: (key: SpecificModelType) => void;
}

const ModelContext = createContext<ModelContextType | undefined>(undefined);

export function ModelProvider({
  initialModel,
  children,
}: {
  initialModel: 'openai' | 'gemini';
  children: ReactNode;
}) {
  const [model, setModel] = useState<ModelType>(initialModel);
  const [specificModelKey, setSpecificModelKey] = useState<SpecificModelType>('muse-echo');
  const [geminiVoice, setGeminiVoice] = useState<string>(defaultGeminiVoice);
  const [openAiVoice, setOpenAiVoice] = useState<string>(defaultOpenaiVoice);

  return (
    <ModelContext.Provider
      value={{
        model,
        setModel,
        geminiVoice,
        setGeminiVoice,
        openAiVoice,
        setOpenAiVoice,
        specificModelKey,
        setSpecificModelKey,
      }}
    >
      {children}
    </ModelContext.Provider>
  );
}

export function useModel() {
  const context = useContext(ModelContext);
  if (context === undefined) {
    throw new Error('useModel must be used within a ModelProvider');
  }
  return context;
}
