import {
  texanPrompt,
  asianPrompt,
  genzPrompt,
  milennialPrompt,
  indianPrompt,
  lustyPrompt
 } from './systemPrompts';

export interface Voices {
  gemini: string;
  openai: string;
}

export interface Persona {
  name: string;
  displayName: string;
  description: string;
  systemPrompt: string;
  voices: Voices;
}

export const personas: Persona[] = [
  {
    name: 'texan',
    displayName: 'Cowboy',
    description: 'Friendly wisdom from Texas',
    systemPrompt: texanPrompt,
    voices: {
      openai: 'Cosmo',
      gemini: 'Vector'
    }
  },
  // {
  //   name: 'asian',
  //   displayName: 'Sensei',
  //   description: 'Bringing harmony through wisdom',
  //   systemPrompt: asianPrompt,
  //   voices: {
  //     openai: 'Nova',
  //     gemini: 'Helix'
  //   }
  // },
  {
    name: 'indian',
    displayName: 'Guru',
    description: 'Ancient wisdom brings inner peace',
    systemPrompt: indianPrompt,
    voices: {
      openai: 'Cosmo',
      gemini: 'Vector'
    }
  },
  {
    name: 'genz',
    displayName: 'Zoomer',
    description: 'No cap fr fr',
    systemPrompt: genzPrompt,
    voices: {
      openai: 'Prism',
      gemini: 'Atlas'
    }
  },
  {
    name: 'milennial',
    displayName: 'Avocado Toast',
    description: 'Living that best life',
    systemPrompt: milennialPrompt,
    voices: {
      openai: 'Luna',
      gemini: 'Astra'
    }
  },
  {
    name: 'lusty',
    displayName: 'Seductive',
    description: 'Making everything sound romantic',
    systemPrompt: lustyPrompt,
    voices: {
      openai: 'Aurora',
      gemini: 'Solstice'
    }
  }
];