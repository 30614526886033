import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Navbar from '../components/Navbar';
import StackedAreaChart from '../components/StackedAreaChart';
import { MetricDataPoint, MetricsTimeseriesData } from '../eval-timeseries-types';
import { Button } from '../components/ui/button';
import { DiscordLogo } from '@phosphor-icons/react/dist/ssr';

const convMetricsToTrack = ['mean_ipu_duration', 'mean_pause_duration', 'mean_gap_duration', 'mean_overlap_duration'];
const reasoningMetricsToTrack = ['ai2d'];

const getMetricDescription = (metricName: string) => {
  const metricsDescriptions: Record<string, string> = {
    'mean_ipu_duration': 'Length of continuous speech between silences (HIGHER is typically better - longer speech segments feel more natural)',
    'mean_pause_duration':
      "Duration of silence between same speaker's segments (LOWER is better - shorter pauses indicate more fluent speech)",
    'mean_gap_duration': 'Duration of silence between different speakers (LOWER is better - shorter gaps show better response time)',
    'mean_overlap_duration':
      'Duration of simultaneous speech (MODERATE is better - some overlap is natural ~0.2-0.5s, but too much means interruption)',
    // 'num_turns': 'Number of turns (HIGHER is better - more turns means more conversation)',
    'ai2d': 'AI2D evaluates model performance on a dataset of 5000+ grade school science diagrams with rich annotations and multiple choice questions. The score reflects how well the model can interpret and reason about scientific diagrams. (HIGHER is better - higher scores indicate better diagram understanding)',
  };

  if (metricName in metricsDescriptions) {
    return metricsDescriptions[metricName];
  }

  return "";
}

const getMetricDisplayName = (metricName: string) => {
  const metricDisplayNames: Record<string, string> = {
    'mean_ipu_duration': 'Inter-Pausal Units',
    'mean_pause_duration': 'Pauses',
    'mean_gap_duration': 'Gap Duration',
    'mean_overlap_duration': 'Overlap Duration',
    'ai2d': 'AI2D',
    // 'num_turns': 'Number of Turns',
  };

  if (metricName in metricDisplayNames) {
    return metricDisplayNames[metricName];
  }

  const tokens = metricName.split('_').join(' ');
  return tokens.charAt(0).toUpperCase() + tokens.slice(1);
};

const EvalDashboard = () => {
  const { data: apiData, isLoading } = useQuery({
    queryKey: ['evaldata'],
    queryFn: async () => {
      const resp = await axios.get<{
        success: boolean;
        data: MetricsTimeseriesData;
      }>(`${process.env.REACT_APP_SN21_VALI_API_URL}/get-eval-metrics`);
      return resp.data.data;
    },
  });

  const colors = [
    '#FF55EE',
    '#55BEFF',
    '#63FF55',
    '#FFC955',
    '#FF557C',
    '#B255FF',
  ];

  return (
    <section className="w-full min-h-screen bg-black text-white">
      <Navbar />
      <div className="flex flex-col gap-4 justify-center bg-[url('./assets/dash-hero.png')] bg-cover bg-center relative before:absolute before:inset-0 p-4 md:p-8 lg:p-12 min-h-[20vh] md:min-h-[40vh] border-b border-white/20 w-full md:mt-20">
        <div className="relative z-10 w-full">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-extrabold mb-4">
            SN21 Model Benchmarking Dashboard
          </h1>
          <p className="text-sm md:text-base text-white/80 mb-6 max-w-prose">
            Watch SN21 models evolve in real-time. Track daily improvements across reasoning, vision, and conversation benchmarks, comparing directly against leading AI models.
          </p>
          <Button className="bg-black/50 rounded-lg px-3 py-1.5 border border-white/20 focus:outline-none hover:opacity-90 w-fit hover:border-white/70 transition-all ease-in-out duration-200">
            <a
              href="https://discord.gg/ccrvTpetKY"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 text-sm md:text-base"
            >
              <DiscordLogo size={24} className="md:w-8 md:h-8" />
              Join our Discord Channel
            </a>
          </Button>
        </div>
      </div>

      <div className="w-full md:px-4 md:w-11/12 lg:w-9/12 mx-auto mt-8 md:mt-10 space-y-6 md:space-y-8">
        <h1 className="text-4xl font-bold pt-4">Conversational Intelligence Leaderboard</h1>
        <div className="overflow-x-auto">
          <table className="w-full text-sm text-left text-white/90">
            <thead className="text-xs uppercase bg-white/10">
              <tr>
                <th className="px-6 py-3">Rank</th>
                <th className="px-6 py-3">Team</th>
                <th className="px-6 py-3">Model</th>
                <th className="px-6 py-3">AlpacaEval</th>
                <th className="px-6 py-3">CommonEval</th>
                <th className="px-6 py-3">SDQA</th>
                <th className="px-6 py-3">OpenBookQA</th>
                <th className="px-6 py-3">IFEval</th>
                <th className="px-6 py-3">AdvBench</th>
                <th className="px-6 py-3">Overall</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b border-white/10">
                <td className="px-6 py-4">1</td>
                <td className="px-6 py-4">OpenAI</td>
                <td className="px-6 py-4">GPT-4o Realtime</td>
                <td className="px-6 py-4">4.78</td>
                <td className="px-6 py-4">4.49</td>
                <td className="px-6 py-4">75.50</td>
                <td className="px-6 py-4">89.23</td>
                <td className="px-6 py-4">76.02</td>
                <td className="px-6 py-4">98.65</td>
                <td className="px-6 py-4 font-bold">86.42</td>
              </tr>
              <tr className="border-b border-white/10">
                <td className="px-6 py-4">2</td>
                <td className="px-6 py-4">OpenAI</td>
                <td className="px-6 py-4">GPT-4o-mini Realtime</td>
                <td className="px-6 py-4">4.75</td>
                <td className="px-6 py-4">4.24</td>
                <td className="px-6 py-4">67.36</td>
                <td className="px-6 py-4">84.84</td>
                <td className="px-6 py-4">72.90</td>
                <td className="px-6 py-4">98.27</td>
                <td className="px-6 py-4 font-bold">82.30</td>
              </tr>
              <tr className="border-b border-white/10 bg-white/5">
                <td className="px-6 py-4">3</td>
                <td className="px-6 py-4">SN21</td>
                <td className="px-6 py-4 font-bold">aizhibin/omega_titanic_0x03</td>
                <td className="px-6 py-4">2.32</td>
                <td className="px-6 py-4">2.18</td>
                <td className="px-6 py-4">9.31</td>
                <td className="px-6 py-4">26.59</td>
                <td className="px-6 py-4">11.56</td>
                <td className="px-6 py-4">57.50</td>
                <td className="px-6 py-4 font-bold">31.32</td>
              </tr>
              <tr className="border-b border-white/10">
                <td className="px-6 py-4">4</td>
                <td className="px-6 py-4">SN21</td>
                <td className="px-6 py-4">ronan401/magic_baby</td>
                <td className="px-6 py-4">2.01</td>
                <td className="px-6 py-4">1.60</td>
                <td className="px-6 py-4">15.64</td>
                <td className="px-6 py-4">25.93</td>
                <td className="px-6 py-4">10.12</td>
                <td className="px-6 py-4">44.23</td>
                <td className="px-6 py-4 font-bold">27.47</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="w-full md:px-4 md:w-11/12 lg:w-9/12 mx-auto mt-8 md:mt-10 space-y-6 md:space-y-8">
        <h1 className="text-4xl font-bold pt-4">Conversational Fluency</h1>
        {apiData &&
          Object.entries(apiData as Record<string, MetricDataPoint[]>)
            .filter(([metric]) => convMetricsToTrack.includes(metric))
            .map(([metric, metricData]) => (
              <div key={metric} className="w-full">
                <StackedAreaChart
                  data={metricData}
                  title={getMetricDisplayName(metric)}
                  description={getMetricDescription(metric)}
                  colors={colors}
                />
              </div>
            ))}
      </div>

      <div className="w-full md:px-4 md:w-11/12 lg:w-9/12 mx-auto mt-8 md:mt-10 space-y-6 md:space-y-8">
        <h1 className="text-4xl font-bold pt-4">Visual Reasoning Metrics</h1>
        {apiData &&
          Object.entries(apiData as Record<string, MetricDataPoint[]>)
            .filter(([metric]) => reasoningMetricsToTrack.includes(metric))
            .map(([metric, metricData]) => (
              <div key={metric} className="w-full">
                <StackedAreaChart
                  data={metricData}
                  title={getMetricDisplayName(metric)}
                  description={getMetricDescription(metric)}
                  colors={colors}
                />
              </div>
            ))}
      </div>

      <footer className="bg-black text-white/60 py-8 md:py-16 mt-12 md:mt-20 border-t border-white/10">
        <div className="w-11/12 lg:w-9/12 mx-auto grid grid-cols-1 md:grid-cols-4 gap-6 md:gap-8 text-center md:text-left">
          {/* Company Section */}
          <div className="space-y-3 md:space-y-4">
            <h3 className="text-white font-medium">Company</h3>
            <div className="space-y-2">
              <p>OMEGA Labs Inc.</p>
              <p>Made with love on Earth</p>
              <p className="hover:text-white cursor-pointer transition-colors">
                Careers
              </p>
            </div>
          </div>

          {/* Contact Section */}
          <div className="space-y-3 md:space-y-4">
            <h3 className="text-white font-medium">Contact</h3>
            <p className="hover:text-white cursor-pointer transition-colors break-words">
              <a
                href="mailto:contact@omega-labs.ai"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact@omega-labs.ai
              </a>
            </p>
          </div>

          {/* Socials Section */}
          <div className="space-y-3 md:space-y-4">
            <h3 className="text-white font-medium">Socials</h3>
            <div className="space-y-2">
              <p className="hover:text-white cursor-pointer transition-colors">
                <a
                  href="https://twitter.com/omegalabsai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  omegalabsai on X
                </a>
              </p>
              <p className="hover:text-white cursor-pointer transition-colors">
                <a
                  href="https://discord.gg/ccrvTpetKY"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Join our Discord
                </a>
              </p>
            </div>
          </div>

          {/* Downloads Section */}
          <div className="space-y-3 md:space-y-4">
            <h3 className="text-white font-medium">Downloads</h3>
            <div className="space-y-2">
              <p className="hover:text-white cursor-pointer transition-colors">
                <a
                  href="https://focus.omega.inc"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sign in to OMEGA Focus
                </a>
              </p>
              <p className="hover:text-white cursor-pointer transition-colors">
                <a
                  href="https://api-focus.omegatron.ai/download/mac_companion"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  OMEGA Focus for Mac
                </a>
              </p>
              <p className="hover:text-white cursor-pointer transition-colors">
                <a
                  href="https://api-focus.omegatron.ai/download/windows_companion"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  OMEGA Focus for Windows
                </a>
              </p>
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="w-full lg:w-9/12 mx-auto mt-8 md:mt-16 pt-6 md:pt-8 border-t border-white/20 flex flex-col items-center space-y-2">
          <div className="text-xl md:text-2xl">Ω</div>
          <p className="text-xs md:text-sm text-center">
            © 2024 OMEGA Labs Inc. All rights reserved.
          </p>
        </div>
      </footer>
    </section>
  );
};

export default EvalDashboard;
