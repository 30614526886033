/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  MultimodalLiveAPIClientConnection,
  MultimodalLiveClient,
} from '../gemini/multimodal-live-client';
import { LiveConfig } from '../multimodal-live-types';
import { AudioStreamer } from '../gemini/audio-streamer';
import { audioContext } from '../gemini/utils';
import VolMeterWorket from '../gemini/worklets/vol-meter';
import { useWebcam } from './use-webcam';
import { useScreenCapture } from './use-screen-capture';
import { defaultGeminiVoice, systemPrompt } from '../constants/voices';
import { useModel } from '../contexts/ModelContext';
import { usePersonaStore } from '../stores/persona-store';

export type UseLiveAPIResults = {
  client: MultimodalLiveClient;
  config: LiveConfig;
  connected: boolean;
  connect: () => Promise<void>;
  disconnect: () => Promise<void>;
  volume: number;
};

export function useLiveAPI({
  url,
}: MultimodalLiveAPIClientConnection): UseLiveAPIResults {
  if (!url) {
    throw new Error('url is required');
  }
  const client = useMemo(() => new MultimodalLiveClient({ url }), [url]);
  const audioStreamerRef = useRef<AudioStreamer | null>(null);
  const { stop: stopWebcam, isStreaming: isWebcamStreaming } = useWebcam();
  const { stop: stopScreenCapture, isStreaming: isScreenCapturing } = useScreenCapture();
  const activePersona = usePersonaStore(store => store.activePersona);
  const { specificModelKey } = useModel();
  const isNativeGemini = specificModelKey === 'muse-sage';

  const [connected, setConnected] = useState(false);
  const { geminiVoice: voice } = useModel();

  const config = useMemo(() => ({
    model: isNativeGemini ? "models/gemini-2.0-flash-exp" : specificModelKey,
    systemInstruction: {
      parts: [{ text: activePersona.systemPrompt }],
    },
    generationConfig: {
      responseModalities: 'audio',
      speechConfig: {
        voiceConfig: {
          prebuiltVoiceConfig: {
            voiceName: activePersona.voices.gemini || defaultGeminiVoice,
          },
        },
      },
    },
  }), [isNativeGemini, specificModelKey, activePersona]);

  const [volume, setVolume] = useState(0);

  useEffect(() => {
    if (!audioStreamerRef.current) {
      audioContext({ id: 'audio-out' }).then((audioCtx: AudioContext) => {
        audioStreamerRef.current = new AudioStreamer(audioCtx);
        audioStreamerRef.current
          .addWorklet('vumeter-out', VolMeterWorket, (ev) => {
            setVolume(ev.data.volume);
          })
          .then(() => {
            // Successfully added worklet
          });
      });
    }
  }, [audioStreamerRef]);

  useEffect(() => {
    const onClose = () => {
      setConnected(false);
    };

    const stopAudioStreamer = () => audioStreamerRef.current?.stop();

    const onAudio = (data: ArrayBuffer) => 
      audioStreamerRef.current?.addPCM16(new Uint8Array(data));

    client
      .on('close', onClose)
      .on('interrupted', stopAudioStreamer)
      .on('audio', onAudio);

    return () => {
      client
        .off('close', onClose)
        .off('interrupted', stopAudioStreamer)
        .off('audio', onAudio);
    };
  }, [client]);

  const connect = useCallback(async () => {
    if (!config) {
      throw new Error('config has not been set');
    }
    if(connected) {
      client.disconnect();
    }
    const path = isNativeGemini ? 'multimodal' : 'omega';
    client.setUrl(`${url}/${path}`);
    // @ts-ignore
    await client.connect(config);
    client.send({ text: 'Hi' });
    setConnected(true);
  }, [client, config, connected, isNativeGemini, url]);

  const disconnect = useCallback(async () => {
    if(isWebcamStreaming) {
      stopWebcam();
    }
    if(isScreenCapturing) {
      stopScreenCapture();
    }
    client.disconnect();
    setConnected(false);
    audioStreamerRef.current?.stop();
  }, [setConnected, client]);

  return {
    client,
    // @ts-ignore
    config,
    connected,
    connect,
    disconnect,
    volume,
  };
}
