import { useModel, ModelCatalogue } from '../../contexts/ModelContext';
import { VideoCamera, PhoneCall } from '@phosphor-icons/react';

const ModelCard = ({ model, onClick }: { model: typeof ModelCatalogue[0], onClick?: () => void }) => {
    const {
        setModel,
        specificModelKey,
        setSpecificModelKey,
    } = useModel();
    const isProprietary = model.key.startsWith('muse');
    const isOpenAi = model.key === 'muse-echo';
    const isSelected = model.key === specificModelKey;
    return (
        <div
        onClick={() => {
            setModel(isOpenAi ? 'openai' : 'gemini');
            setSpecificModelKey(model.key);
            if (onClick) {
                onClick();
            }
        }}
        className={`p-4 border ${isSelected ? 'border-white' : 'border-white/20'} rounded-lg hover:border-white/70 transition-colors relative cursor-pointer w-full bg-black/70`}
        >
        <div>
            <div className="pb-2 flex justify-between items-center">
            <div className="flex items-center">
                <h3 className="font-bold text-white">{model.name}</h3>
            </div>
            {!isProprietary && (<span className={`text-xs px-2 py-1 rounded ${
                isProprietary
                ? 'bg-purple-500/20 text-purple-200'
                : 'bg-green-500/20 text-green-200'
            }`}>
                {isProprietary ? 'Proprietary' : 'SN21 Model'}
            </span>)}
            </div>
            <p className="text-sm text-white/70 w-[70%]">{model.description}</p>
            {!isProprietary && (
            <div className="mt-3 flex flex-col items-start gap-2">
                {model.hotkey && <span className="text-xs text-white/50 px-2 py-1 bg-white/10 rounded">
                {model.hotkey}
                </span>}
                {model.block && <span className="text-xs text-white/50 px-2 py-1 bg-white/10 rounded">
                Block {model.block}
                </span>}
            </div>
            )}
            <div className="absolute bottom-4 right-4 flex gap-2">
            {model.hasVideo && <VideoCamera size={20} weight='fill' className="text-white/70" />}
            <PhoneCall size={20} weight='fill' className="text-white/70" />
            </div>
        </div>
        </div>
    );
};

export default ModelCard;
