import React, { useState, useEffect, memo } from 'react';
import {
  Brain,
  Microphone,
  MicrophoneSlash,
  MonitorArrowUp,
  PhoneDisconnect,
  VideoCamera,
} from '@phosphor-icons/react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '../ui/button';
import { AudioRecorder } from '../../gemini/audio-recorder';
import { useModel, ModelCatalogue } from '../../contexts/ModelContext';
import { useWebcam } from '../../hooks/use-webcam';
import { geminiΩVoices, openaiΩVoices } from '../../constants/voices';
import { useScreenCapture } from '../../hooks/use-screen-capture';
import VideoFeed from '../VideoFeed';
import HiddenVideoContext from './HiddenVIdeoContext';
import { cn } from '../../utils/cn';
import ModelCard from './ModelCard';
import { personas } from '../../constants/personas';
import { usePersonaStore } from '../../stores/persona-store';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

interface IconButtonProps {
  isOn: boolean;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  children: React.ReactNode;
}

const IconButton = memo(
  ({ isOn, onClick, disabled = false, children }: IconButtonProps) => {
    return (
      <Button
        disabled={disabled}
        className={cn(
          'relative aspect-square p-4 h-full col-span-2',
          'bg-black rounded-lg',
          'transition-all duration-200',
          isOn
            ? [
                'before:absolute before:inset-0',
                'before:rounded-lg before:p-[1px]',
                'before:bg-gradient-to-r before:from-[#FBE7FF] before:to-[#7160E9]',
                'before:content-[""]',
                'hover:before:opacity-80',
              ]
            : ['border border-white/15']
        )}
        onClick={onClick}
      >
        <span
          className={cn(
            'absolute inset-[1px] rounded-[7px] bg-black z-0 flex justify-center items-center'
          )}
        >
          {children}
        </span>
      </Button>
    );
  }
);

interface ModelOptionsProps {
  voices: string[];
  model: string;
  voice: string;
  changeVoice: (voice: string) => void;
  handleModelSwitch: (model: 'openai' | 'gemini') => void;
  specificModelKey: string;
}

const ModelOptionsDrawer = React.memo(
  ({
    voices,
    model,
    voice,
    changeVoice,
    handleModelSwitch,
    specificModelKey,
  }: ModelOptionsProps) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <>
        <Button
          className={`border px-2 rounded-lg hover:border-white/70 aspect-square col-span-1 flex items-center justify-center ${
            isOpen ? 'border-white/70' : 'border-white/15'
          } z-[101] h-full aspect-square rounded-xl col-span-2`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Brain size={28} />
        </Button>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.2
                }
              }}
              exit={{
                opacity: 0,
                y: 20,
                transition: {
                  duration: 0.2
                }
              }}
              className="fixed inset-x-0 bottom-[4rem] z-[100] backdrop-blur-md mb-8"
            >
              <div className="max-w-md w-full">
                {/* {(ModelCatalogue.find(model => model.key === specificModelKey)?.key.startsWith('muse')) && (<div className="flex flex-col items-center gap-3 border border-white/10 bg-white/5 rounded-2xl p-3 w-full mb-4">
                  <h2 className="font-bold text-white mb-1 text-center text-sm text-white">
                  Muse Voices
                  </h2>
                  <select
                    value={voice}
                    onChange={(e) => changeVoice(e.target.value)}
                    className="w-full px-4 py-3 rounded-xl bg-black/30 border border-white/10 text-white/90
                    appearance-none cursor-pointer hover:border-white/30 transition-colors
                    focus:outline-none focus:ring-2 focus:ring-white/20
                    text-sm font-medium tracking-wide
                    bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMUw2IDYgMTEgMSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=')]
                    bg-[length:12px] bg-[calc(100%-16px)_center] bg-no-repeat"
                  >
                    {voices.map((voiceOption) => (
                      <option
                        key={voiceOption}
                        value={voiceOption}
                        className="bg-black/90 text-white/90"
                      >
                        {voiceOption}
                      </option>
                    ))}
                  </select>
                </div>)} */}
                <div className="flex flex-col items-center gap-3 border border-white/10 bg-white/5 rounded-2xl p-3 w-full">
                  <h2 className="font-bold text-white mb-1 text-center text-sm text-white">
                  Muse Models
                  </h2>
                  {ModelCatalogue.map((model) => (
                    <ModelCard key={model.key} model={model} />
                  ))}
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  }
);

export default function PhoneControlTray({
  isConnected,
  handleDisconnect,
  handleConnect,
  client,
}: {
  isConnected: boolean;
  handleDisconnect: () => void;
  handleConnect: () => Promise<void>;
  client: any
}) {
  const { geminiVoice, openAiVoice } = useModel();
  const [audioRecorder] = useState(() => new AudioRecorder());
  const [muted, setMuted] = useState(false);
  const {
    model,
    setModel,
    setOpenAiVoice,
    setGeminiVoice,
    specificModelKey,
  } = useModel();

  const {
    isStreaming: isWebcamStreaming,
    start: startWebcam,
    stop: stopWebcam,
    stream: webcamStream,
  } = useWebcam();

  const {
    isStreaming: isScreenCapturing,
    start: startScreenCapture,
    stop: stopScreenCapture,
    stream: screenStream,
  } = useScreenCapture();

  const currentVoice = model === 'openai' ? openAiVoice : geminiVoice;
  
  const voices = model === 'openai' ? openaiΩVoices : geminiΩVoices;
  
  const disconnect = () => {
    stopScreenCapture();
    stopWebcam();
    handleDisconnect();
  }

  const handleModelSwitch = (newModel: 'openai' | 'gemini') => {
    if (isConnected) {
      handleDisconnect();
    }
    setModel(newModel);
  };

  const handleVoiceChange = (voice: string) => {
    if (model === 'openai') {
      setOpenAiVoice(voice);
    } else {
      setGeminiVoice(voice);
    }
  };

  const handleScreenShare = async () => {
    if (model === 'openai') return;
    if (isScreenCapturing) {
      stopScreenCapture();
    } else {
      await startScreenCapture();
    }
  };

  const handleCameraShare = async () => {
    if (model === 'openai') return;
    if (isWebcamStreaming) {
      stopWebcam();
    } else {
      await startWebcam();
    }
  };

  const baseVideoClasses =
    'fixed aspect-[9/16] bg-black rounded-lg overflow-hidden';

  const webcamClasses = `${baseVideoClasses} z-[130] ${
    isScreenCapturing
      ? 'bottom-[8rem] right-[1.25rem] w-14'
      : 'bottom-[8rem] right-[1.25rem] w-28'
  }`;

  const screenClasses = `${baseVideoClasses} z-[120] ${
    isWebcamStreaming
      ? 'bottom-[9rem] right-[2.25rem] w-28'
      : 'bottom-[8rem] right-[1.25rem] w-28'
  }`;

  useEffect(() => {
    if (model === 'gemini') {
      const onData = (base64: string) => {
        client.sendRealtimeInput([
          {
            mimeType: 'audio/pcm;rate=16000',
            data: base64,
          },
        ]);
      };
      if (isConnected && !muted && audioRecorder) {
        audioRecorder.on('data', onData).start();
      } else {
        audioRecorder.stop();
      }
      return () => {
        audioRecorder.off('data', onData);
      };
    }
  }, [isConnected, client, muted, audioRecorder, model]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (
        (event.key === 'm' || event.key === 'M') &&
        !(event.target instanceof HTMLInputElement) &&
        !(event.target instanceof HTMLTextAreaElement)
      ) {
        setMuted((prev) => !prev);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  const { activePersona, setActivePersona } = usePersonaStore(store => store);

  return (
    <>
      {webcamStream && (
        <VideoFeed stream={webcamStream} className={webcamClasses} />
      )}
      {screenStream && (
        <VideoFeed stream={screenStream} className={screenClasses} />
      )}

      {model === 'gemini' && (
        <HiddenVideoContext
          client={client}
          isConnected={isConnected}
          webcamStream={webcamStream}
          screenStream={screenStream}
        />
      )}

      <div className="z-[140] w-11/12 mx-auto fixed bottom-[120px] mb-4 left-[50%] translate-x-[-50%]">
        <Select
          value={activePersona.name}
          onValueChange={(value) => {
            const persona = personas.find((p) => p.name === value);
            if (persona) {
              setActivePersona(persona);
            }
          }}
        >
          <SelectTrigger className="w-full bg-transparent border-white/30 hover:border-white/70 transition-colors rounded-full py-2 px-4 h-[7ch]">
            <SelectValue placeholder="Choose your AI personality" />
          </SelectTrigger>
          <SelectContent
            className="bg-black/95 border-white/30 rounded-lg w-full"
            position="popper"
            sideOffset={0}
          >
            {personas.map((persona) => (
              <SelectItem
                key={persona.name}
                value={persona.name}
                className="text-white focus:bg-white/10 data-[state=checked]:bg-white/10"
              >
                <div className="flex flex-col justify-center text-left px-2">
                  <div className="font-medium text-base">
                    {persona.displayName}
                  </div>
                  <div className="text-sm text-white/60 font-normal text-wrap">
                    {persona.description}
                  </div>
                </div>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="z-[140] w-11/12 p-2 mx-auto border border-white/10 rounded-2xl bg-black/30 mb-10 grid grid-cols-11 gap-2 fixed bottom-0 left-[50%] translate-x-[-50%]">
        {isConnected ? (
          <>
            <ModelOptionsDrawer
              specificModelKey={specificModelKey}
              voices={voices}
              model={model}
              voice={currentVoice}
              changeVoice={handleVoiceChange}
              handleModelSwitch={handleModelSwitch}
            />
            <IconButton
              disabled={model === 'openai'}
              isOn={isWebcamStreaming}
              onClick={handleCameraShare}
            >
              <VideoCamera size={28} />
            </IconButton>
            <Button
              onClick={disconnect}
              className="rounded-full font-bold group-hover:scale-[1.01] transition-all ease-in-out duration-200 col-span-3 h-full bg-disconnect text-white"
            >
              <PhoneDisconnect
                weight="fill"
                size={36}
                className="text-white w-10"
              />
            </Button>
            <IconButton
              disabled={model === 'openai'}
              isOn={isScreenCapturing}
              onClick={handleScreenShare}
            >
              <MonitorArrowUp size={28} />
            </IconButton>
            <Button
              onClick={() => setMuted(!muted)}
              className={`border flex items-center justify-center h-full aspect-square rounded-xl col-span-2 ${
                muted
                  ? 'border-disconnect/50 text-disconnect'
                  : 'border-white/15 text-white'
              }`}
            >
              {muted ? <MicrophoneSlash size={28} /> : <Microphone size={28} />}
            </Button>
          </>
        ) : (
          <>
            <ModelOptionsDrawer
              specificModelKey={specificModelKey}
              voices={voices}
              model={model}
              voice={currentVoice}
              changeVoice={handleVoiceChange}
              handleModelSwitch={handleModelSwitch}
            />
            <Button
              onClick={handleConnect}
              className="bg-white text-black rounded-xl font-bold group-hover:scale-[1.01] transition-all ease-in-out duration-200 col-start-4 col-span-5 h-full"
            >
              Call
              <br />
              OMEGA Muse
            </Button>
          </>
        )}
      </div>
    </>
  );
}
